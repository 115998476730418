
import { useUsers } from "@kunsten/core";
import { computed, defineComponent, ref } from "vue";
import CollectionsTop from "./CollectionsTop.vue";
import InputDebounce from "../../../shared/components/InputDebounce.vue";
import IconText from "../../../shared/components/IconText.vue";
import MyCollectionsNotLogged from "@/modules/collections/shared/MyCollectionsNotLogged.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "MyCollectionsTop",
    components: { MyCollectionsNotLogged, CollectionsTop, InputDebounce, IconText },
    props: {
        ownerUuid: {
            type: String,
        },
    },
    setup(props) {
        const { getUser, patchUser } = useUsers();
        const fullName = ref("");
        const isEditing = ref(false);
        const loading = ref(true);
        const loadingEdit = ref(false);
        const showGuide = ref(false);
        const { t } = useI18n();

        if (props.ownerUuid) {
            getUser(props.ownerUuid).then((res) => {
                fullName.value = res.data?.fullName ?? "";
                loading.value = false;
            });
        }

        const onInput = (value: string) => {
            if (props.ownerUuid && value) {
                loadingEdit.value = true;
                fullName.value = value;
                patchUser(props.ownerUuid, { fullName: value })
                    .then(() => {
                        loadingEdit.value = false;
                    })
                    .catch(() => {
                        loadingEdit.value = false;
                    });
            }
        };

        const toggleGuide = () => {
            showGuide.value = !showGuide.value;
        };

        return { onInput, fullName, loading, isEditing, loadingEdit, showGuide, toggleGuide };
    },
});
