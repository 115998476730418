
import { useAPI, useUserCollections } from "@kunsten/core";
import { defineComponent } from "vue";
import Spinner from "../../../shared/components/Spinner.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "CollectionAddBlock",
    components: { Spinner },
    props: {
        public: {
            type: Boolean,
            required: true,
        },
        loading: Boolean,
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const { userData } = useAPI();
        const { postUserCollection } = useUserCollections();

        const create = () => {
            postUserCollection({ title: t("collection.my-favorites"), owner: `/api/users/${userData.value?.id}` }).then(
                () => {
                    emit("refresh");
                }
            );
        };

        return { create };
    },
});
