import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "my-collections-list pt-6 pb-4" }
const _hoisted_2 = { class: "page-container" }
const _hoisted_3 = { class: "columns" }
const _hoisted_4 = { class: "column is-one-third-tablet is-one-quarter-desktop" }
const _hoisted_5 = { class: "column is-two-third-tablet is-three-quarter-desktop" }
const _hoisted_6 = { class: "columns is-multiline" }
const _hoisted_7 = {
  key: 0,
  class: "columns is-multiline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CollectionAddBlock = _resolveComponent("CollectionAddBlock")!
  const _component_CollectionBlock = _resolveComponent("CollectionBlock")!
  const _component_SpinnerContainer = _resolveComponent("SpinnerContainer")!
  const _component_IsOwnedByUser = _resolveComponent("IsOwnedByUser")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_IsOwnedByUser, { ownerIri: _ctx.ownerIri }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_CollectionAddBlock, {
                loading: _ctx.loading,
                onRefresh: _ctx.refresh
              }, null, 8, ["loading", "onRefresh"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (collection) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "column is-half-tablet is-one-third-desktop",
                    key: collection['@id']
                  }, [
                    _createVNode(_component_CollectionBlock, { collection: collection }, null, 8, ["collection"])
                  ]))
                }), 128))
              ])
            ])
          ])
        ]),
        isNotOwned: _withCtx(() => [
          (!_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (collection) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "column is-half-tablet is-one-third-desktop is-one-quarter-widescreen",
                    key: collection['@id']
                  }, [
                    _createVNode(_component_CollectionBlock, { collection: collection }, null, 8, ["collection"])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createBlock(_component_SpinnerContainer, { key: 1 }))
        ]),
        _: 1
      }, 8, ["ownerIri"])
    ])
  ]))
}