
import { defineComponent } from "vue";
import MyCollectionsList from "../shared/MyCollectionsList.vue";
import MyCollectionsTop from "../shared/MyCollectionsTop.vue";
import MyCollectionsNotLogged from "../shared/MyCollectionsNotLogged.vue";
import PopularCollections from "../../../shared/components/PopularCollections.vue";
import { useDocumentTitle, useTokenCredentials } from "@kunsten/core";
import { IsNotAuthenticated, IsAuthenticated } from "@kunsten/core";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "MyCollections",
    components: {
        IsNotAuthenticated,
        IsAuthenticated,
        MyCollectionsTop,
        MyCollectionsList,
        MyCollectionsNotLogged,
        PopularCollections,
    },
    setup() {
        const { userUuid, userIri } = useTokenCredentials();
        const { t } = useI18n();

        useDocumentTitle(t("general.base-title"), t("navigation.my-collections"));

        return { userUuid, userIri };
    },
});
