import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c4a4782"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-collections pb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MyCollectionsTop = _resolveComponent("MyCollectionsTop")!
  const _component_MyCollectionsList = _resolveComponent("MyCollectionsList")!
  const _component_PopularCollections = _resolveComponent("PopularCollections")!
  const _component_IsAuthenticated = _resolveComponent("IsAuthenticated")!
  const _component_MyCollectionsNotLogged = _resolveComponent("MyCollectionsNotLogged")!
  const _component_IsNotAuthenticated = _resolveComponent("IsNotAuthenticated")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IsAuthenticated, null, {
      default: _withCtx(() => [
        _createVNode(_component_MyCollectionsTop, { ownerUuid: _ctx.userUuid }, null, 8, ["ownerUuid"]),
        _createVNode(_component_MyCollectionsList, { ownerIri: _ctx.userIri }, null, 8, ["ownerIri"]),
        _createVNode(_component_PopularCollections)
      ]),
      _: 1
    }),
    _createVNode(_component_IsNotAuthenticated, null, {
      default: _withCtx(() => [
        _createVNode(_component_MyCollectionsNotLogged)
      ]),
      _: 1
    })
  ]))
}