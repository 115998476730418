
import { defineComponent } from "vue";
import { useUserCollectionsResource } from "@kunsten/core";
import CollectionBlock from "../../../shared/components/CollectionBlock.vue";
import CollectionAddBlock from "./CollectionAddBlock.vue";
import { IsOwnedByUser } from "@kunsten/core";
import SpinnerContainer from "../../../shared/components/SpinnerContainer.vue";

export default defineComponent({
    name: "MyCollectionsList",
    components: { CollectionBlock, CollectionAddBlock, IsOwnedByUser, SpinnerContainer },
    props: {
        ownerIri: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const {
            entities: results,
            refresh,
            loading,
        } = useUserCollectionsResource({
            page: "1",
            pagination: "false",
            owner: props.ownerIri,
        });

        return { results, refresh, loading };
    },
});
